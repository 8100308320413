import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Redirect} from 'react-router-dom'

import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../images/logo-cbg-corporate.svg";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {ErrorDialog} from "./ErrorDialog";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(550 + theme.spacing(3*2))]: {
            width: 550,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
});

function ResetPassword(props) {
    const { t } = useTranslation();
    const { classes, match } = props;
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [redirect, setRedirect] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({});

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = event => { event.preventDefault(); };

    if (match.params && match.params.sub && match.params.code) {
        const { sub, code } = match.params;

        const re_password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

        const submit = (e) => {
            e.preventDefault();
            setLoading(true);
            if(newPassword.match(re_password)) {
                Auth.forgotPasswordSubmit(sub, code, newPassword)
                    .then(res => {
                        setRedirect('/');
                    }).catch(err => {
                        setError(err);
                        setOpen(true);
                    });
            } else {
                setError({ name: t('InvalidPasswordException') });
                setOpen(true);
            }
        };

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h1">
                        <img style={{width: '100%'}} src={logo} alt="Logo"/>
                    </Typography>
                    <Typography component="h1" variant="h4">
                        {t('changePasswordTitle')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('changePasswordBody')}
                    </Typography>
                    <form className={classes.form} onSubmit={(e) => submit(e)}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">{t('newPassword')}</InputLabel>
                            <Input
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="new-password"
                                onChange={e => setNewPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Typography variant="body1" gutterBottom>
                            { t('passwordRequirements') }
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            className={classes.submit}
                        >
                            {loading ? <CircularProgress className={classes.progress} /> : null}
                            {t('changePassword')}
                        </Button>
                    </form>
                    <ErrorDialog open={open} error={error} onClose={() => { setOpen(false); setLoading(false) }} />
                    { redirect ? <Redirect to={redirect} /> : null}
                </Paper>
            </main>
        );
    } else {
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                        {t('verification')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ongeldige link
                    </Typography>
                </Paper>
            </main>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPassword);
