import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import { DateUtils } from '@aws-amplify/core';

export function useCode() {
  const [code, setCode] = useState(null);
  const [session, setSession] = useState({});

  useEffect(() => {
    async function getCode() {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const session = cognitoUser.getSignInUserSession();
      DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
      setSession(session);
      const apiName = 'oAuth';
      const path = '/oauth2/code';
      let myInit = {
        queryStringParameters: {
          token: session.getIdToken().getJwtToken()
        }
      };
      const {code} = await API.get(apiName, path, myInit);
      setCode(code);
    }

    getCode();
  }, []);

  return [code, session];
}
