import React, {useState} from 'react';
import {withTranslation, useTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import {ConfirmSignUp} from "aws-amplify-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo-cbg-corporate.svg";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3*2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginRight: theme.spacing(3),
  }
});

function SignInButton(props) {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const {onClick} = props;
  return <Button
      type="button"
      fullWidth
      color="primary"
      disabled={disabled}
      onClick={e => { setDisabled(true); onClick(e)}}
  >
    {t('backToSignin')}
  </Button>
}

class CustomConfirmSignUp extends ConfirmSignUp {
  signIn() {
    this.changeState('signIn');
    this.setState({error: false });
  }

  error(err) {
    // this.setState({delivery: true });
    console.error(err);
    this.setState({ error: err.code });
    // switch (err.code) {
    //   case 'InvalidParameterException':
    //     const { authData = {} } = this.props;
    //     break;
    //   default:
    //     const { authData = {} } = this.props;
    //     this.setState({ error: err.code });
    //     break;
    // }
    super.error(err);
  }

  showComponent() {
    const {classes, t, authData} = this.props;
    if(authData.resend) {
      authData.resend = undefined;
      super.resend();
    }
    const email = this.usernameFromAuthData();

    return (
        this.state && this.state.error
            ?
            (this.state.error === 'InvalidParameterException'
              ?
              <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                  <Typography variant="h1">
                    <img style={{width: '100%'}} src={logo} alt="CBG"/>
                  </Typography>
                  <Typography component="h1" variant="h4">
                    {t('error')}
                  </Typography>
                  <Typography variant="body1">
                    {/*{t('account', {email})}*/}
                    {t('UsernameExistsException')}
                  </Typography>
                  <SignInButton onClick={e => {
                    this.signIn();
                  }}/>
                </Paper>
              </main>
                    :
                    <main className={classes.main}>
                      <CssBaseline/>
                      <Paper className={classes.paper}>
                        <Typography variant="h1">
                          <img style={{width: '100%'}} src={logo} alt="CBG"/>
                        </Typography>
                        <Typography component="h1" variant="h4">
                          {t('error')}
                        </Typography>
                        <Typography variant="body1">
                          {t(this.state.error)}
                        </Typography>
                        <SignInButton onClick={e => {
                          this.signIn();
                        }}/>
                      </Paper>
                    </main>
            )
              :
              <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                  <Typography variant="h1">
                    <img style={{width: '100%'}} src={logo} alt="CBG"/>
                  </Typography>
                  <Typography component="h1" variant="h4">
                    {t('confirmSignUp')}
                  </Typography>
                  <Typography variant="body1">
                    {t('confirmUserBody', {email})}
                  </Typography>
                  <SignInButton onClick={e => {
                    this.signIn();
                  }}/>
                </Paper>
              </main>
    );
  }
}

export default withTranslation()(withStyles(styles)(CustomConfirmSignUp));
