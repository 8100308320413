import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: {
      error: "Error",
      signin: "Sign in",
      signin_subtitle: "Been here before? Then log in with your My CBG account",
      signup_action: "Register",
      signup: "Registration",
      order: "Order",
      signup_subtitle:
        'Don’t have a My CBG account yet? Create a free account here to log in to My CBG. The registration is easy and offers numerous <1 href="https://cbg.nl/order/" target="_blank">advantages</1>.',
      signup_info_link: "https://cbg.nl/order/",
      backToSignin: "Back to sign in",
      verification: "Verification",
      verification_body: "Thank you for completing the registration. You can now log in to <1>My CBG</1>",
      email: "Email",
      password: "Password",
      oldPassword: "Current password",
      newPassword: "New password",
      passwordRequirements: "Use eight or more characters with a combination of upper and lower case letters and numbers.",
      signout: "Sign Out",
      forgotPasswordTitle: "Forgotten your password?",
      forgotPassword: "Forgotten your password?",
      forgotPassword_subtitle:
        "Enter the email address of your CBG account below. We will send you an email that you can use to set a new password. If you don't receive a message, check if the email address you entered is correct, and look in the 'Junk mail' folder. If you can't find the answer, please send an email to helpdesk@cbg.nl.",
      resetPassword_subtitle: `We are using a new log-in system. This means that you only have to log in once to be able to use all CBG subscriptions. We have just sent a mail to the email address you provided. We ask you to confirm this address once by clicking on the link in that message. If you don't receive a message, check if the email address you entered is correct, and look in the 'Junk mail' folder. If you can't find the answer, please send an email to <a href="mailto:helpdesk@cbg.nl">helpdesk@cbg.nl</a>.`,
      forgotPassword_delivered:
        "An email has been sent to {{email}}. Click on the link in this email to reset your password. Note: this link is only valid for 15 minutes. If you do not receive an email, check the spam folder and the specified email address. You can close this page.",
      forgotPassword_delivered_required:
        "An email has been sent to {{email}}. Click on the link in this e-mail to set a one-time mandatory new password. Note: this link is only valid for 15 minutes. If you do not receive an email, check the spam folder and the specified email address. You can close this page.",
      forgotPassword_error: "There is no account with e-mail address {{email}}",
      forgotPassword_limit: "Limit Exceeded. Try again later",
      confirmSignUp: "Verify email address",
      confirmUserBody:
        "We have just sent a mail to {{email}}. Please confirm this address by clicking on the link in that message. Note: this link is only valid for 15 minutes. If you do not receive an email, check the spam folder and the specified email address. You can close this page.",
      confirmSignout: "You are logged out. You can close this page.",
      changePasswordTitle: "Change password",
      changePassword: "Change password",
      changePasswordBody: "Enter a new password",
      UserNotFoundException: "Error: The combination of email address and password is incorrect",
      NotAuthorizedException: "Error: The combination of email address and password is incorrect",
      UsernameExistsException: "Error: An account with the given email already exists.",
      InvalidPasswordException: "Error: Invalid password",
      InvalidParameterException: "Error: Invalid password",
      CodeMismatchException: "Error: Invalid verification link",
      Error: "Unknown Error",
      orderStatusSuccess: "Thanks for your order.",
      orderStatusSuccess2: "Your purchase is valid immediately. An invoice will be sent within a few days by e-mail",
      orderStatusError: "Something went wrong with the payment. Please try again.",
      personalInformation: "Personal information",
      requiredFields: "<1>*</1> indicates a required field",
      firstName: "First name",
      initials: "Initials",
      prefix: "Prefix",
      lastName: "Last name",
      gender: "Gender",
      male: "Male",
      female: "Female",
      contactDetails: "Contact details",
      street: "Street",
      houseNumber: "Housenumber",
      houseNumberAddition: "Addition",
      zipcode: "Zipcode",
      city: "City",
      country: "Country",
      phone: "Phone",
      newsLetter: "Newsletter",
      newsLetterSubtitle: "Do you wish to subscribe to the newsletter of CBG|Netherlands Center for family history?",
      news_cbg: "I would like to receive the newsletter from the CBG|Netherlands Center for family history.",
      terms: "Terms & conditions",
      termsDescription: "Do you agree with the <1>terms and conditions</1>? This is necessary to order a subscription.",
      agree: "Agree <1>*</1>",
      pay: "Pay",
      resendCode: "Resend verification link",
      incompatibleProduct:
        "This subscription cannot be ordered, since you already have a running subscription. Choose another subscription or check your profile in My CBG.",
      changeEmail: "Change email address",
      changeEmailBody: "Enter the new email address below. ",
      newEmail: "New email address",
      changeEmail_delivered:
        "An e-mail has been sent to {{email}} with a confirmation link. You are now logged off. You can close this page.",
      logout_newEmail: "Your e-mail address has been changed to {{email}}. You can now log in to My CBG.",
      "First name is required": "The field First name is required",
      "Initials are required": "The field Initials is required",
      "Last name is required": "The field Last name is required",
      "Country is required": "The field Country is required",
      "Housenumber is required": "The field Housenumber is required",
      "Only numbers": "Only numbers",
      "Zipcode is required": "The field Zipcode is required",
      "Vereist formaat voor Nederland": 'Required format for Dutch zipcodes: "1234 AB"',
      "Street is required": "The field Street is required",
      "email in use": "Error: This email address is being used by another account",
      UnexpectedLambdaException: "This action fails for this moment, due to a minor technical failure. Please try again in a few moments.",
      familyname: "Familyname",
      firstnames: "Firstnames",
      birthdate: "Birthdate",
      birthPlace: "Birthplace",
      dateOfDeath: "Date of death",
      placeOfDeatch: "Place of death",
      familynamePartner: "Familyname partner",
      spoedAanvraag: "Urgent request",
      spoedAanvraagSubtitle: "Urgent request (€9,00) p.p.",
      spoed_aanvraag: "spoedAanvraag",
      couponcode: "Discount",
      orderStatusSuccessNro: "Thanks for your order.",
      orderStatusSuccessNro2: "Your purchase is being processed. Your invoice can be found in https://mijn.cbg.nl",
      combiFriendship: "CombiFriendship",
      alreadyMember: "I am already a member or donor:",
      association: "My association",
      membership: "Member or donor number",
    },
  },
  nl: {
    translation: {
      error: "Fout",
      signin: "Inloggen",
      signin_subtitle: "Bestaande gebruiker? Log dan in met je Mijn CBG-account ",
      signup_action: "Maak een account aan",
      signup: "Registreren",
      order: "Bestellen",
      signup_subtitle:
        "Ben je een nieuwe gebruiker? Maak dan gratis een account aan om te kunnen inloggen bij Mijn CBG. De registratie is eenvoudig en biedt bepaalde <1>voordelen</1>.",
      signup_info_link: "https://cbg.nl/bestellen",
      backToSignin: "Terug naar inloggen",
      verification: "Verificatie",
      verification_body: "Bedankt voor het afronden van de registratie. Je kunt nu gelijk inloggen op <1>Mijn CBG</1>",
      email: "E-mailadres",
      password: "Wachtwoord",
      oldPassword: "Huidig wachtwoord",
      newPassword: "Nieuw wachtwoord",
      passwordRequirements: "Gebruik acht of meer tekens met een combinatie van hoofdletters, kleine letters en cijfers.",
      signout: "Uitloggen",
      forgotPasswordTitle: "Wachtwoord opnieuw instellen",
      forgotPassword: "Wachtwoord opnieuw instellen",
      forgotPassword_subtitle:
        "Vul hieronder het e-mailadres van je CBG-account in. We sturen dan een e-mail waarmee je een nieuw wachtwoord kunt instellen. Als je de e-mail niet ontvangt, controleer dan het opgegeven e-mailadres en kijk bij de ongewenste e-mailberichten of het bericht daarin terecht is gekomen.",
      resetPassword_subtitle:
        "Wij maken gebruik van een nieuw systeem om in te loggen. Hierdoor hoef je nog maar één keer in te loggen om gebruik te kunnen maken van alle producten van het CBG. We verzoeken je om eenmalig het opgegeven e-mailadres te bevestigen. Er is zojuist een e-mail bericht verstuurd. Klik op de link in dat bericht. Als je geen e-mail ontvangt controleer dan de spam-map en het opgegeven e-mailadres.",
      forgotPassword_delivered:
        "Er is een e-mail gestuurd naar {{email}}. Klik op de link in deze e-mail om je wachtwoord te herstellen. Let op: deze link is slechts 15 minuten geldig. Geen e-mail ontvangen? Controleer dan de spam-map en het opgegeven e-mailadres. Je kunt dit venster nu sluiten.",
      forgotPassword_delivered_required:
        "Er is een e-mail gestuurd naar {{email}}. Klik op de link in deze e-mail om eenmalig verplicht een nieuw wachtwoord in te stellen. Let op: deze link is slechts 15 minuten geldig. Geen e-mail ontvangen? Controleer dan de spam-map en het opgegeven e-mailadres. Je kunt dit venster nu sluiten.",
      forgotPassword_error: "Er is geen account met het e-mailadres {{email}}",
      forgotPassword_limit: "Limiet overschreden. Probeer het over enige tijd opnieuw",
      confirmSignUp: "E-mailadres verifiëren",
      confirmUserBody:
        "Er is een e-mail gestuurd naar {{email}}. Klik op de bevestigingslink in deze e-mail. Let op: deze link is slechts 15 minuten geldig. Geen e-mail ontvangen? Controleer dan de spam-map en het opgegeven e-mailadres. Je kunt dit venster nu sluiten.",
      confirmSignout: "U bent uitgelogd. U kunt dit venster nu sluiten.",
      changePasswordTitle: "Wachtwoord wijzigen",
      changePassword: "Wachtwoord opnieuw instellen",
      changePasswordBody: "Vul een nieuw wachtwoord in",
      UserNotFoundException: "Foutmelding: De combinatie van e-mailadres en wachtwoord is onjuist",
      NotAuthorizedException: "Foutmelding: De combinatie van e-mailadres en wachtwoord is onjuist",
      UsernameExistsException: "Foutmelding: Er bestaat al een account met dit e-mailadres",
      InvalidPasswordException: "Foutmelding: Ongeldig wachtwoord",
      InvalidParameterException: "Foutmelding: Ongeldig wachtwoord",
      CodeMismatchException: "Foutmelding: Ongeldige verificatielink",
      Error: "Onbekende fout",
      orderStatusSuccess: "Bedankt voor uw bestelling.",
      orderStatusSuccess2:
        "Je kunt direct gebruik maken van het bestelde abonnement. Je ontvangt binnen enkele werkdagen de factuur per e-mail. ",
      orderStatusError: "Er ging iets mis met de betaling. Probeer het opnieuw.",
      personalInformation: "Persoonlijke gegevens",
      requiredFields: "Velden met een <1>*</1> zijn verplicht",
      firstName: "Voornaam",
      initials: "Voorletters",
      prefix: "Tussenvoegsel",
      lastName: "Achternaam",
      gender: "Geslacht",
      male: "Man",
      female: "Vrouw",
      contactDetails: "Contactgegevens",
      street: "Straat",
      houseNumber: "Huisnummer",
      houseNumberAddition: "Toevoeging",
      zipcode: "Postcode",
      city: "Woonplaats",
      country: "Land",
      phone: "Telefoon",
      newsLetter: "Nieuwsbrief",
      newsLetterSubtitle: "Wil je de nieuwsbrieven van het CBG|Centrum voor familiegeschiedenis ontvangen?",
      news_cbg: "Ik wil de nieuwsbrief van het CBG|Centrum voor familiegeschiedenis ontvangen",
      terms: "Voorwaarden",
      termsDescription: "Ik ga akkoord met de <2>algemene voorwaarden</2>. Dit is noodzakelijk om een abonnement te bestellen.",
      agree: "Akkoord <1>*</1>",
      pay: "Betalen",
      resendCode: "Verificatielink opnieuw sturen",
      incompatibleProduct:
        "Dit abonnement kan niet worden besteld, omdat er al een lopend abonnement bestaat. Kies een ander abonnement of bekijk je profiel in Mijn CBG.",
      changeEmail: "E-mailadres wijzigen",
      changeEmailBody: "Vul hier je nieuwe e-mailadres in.",
      newEmail: "Nieuw e-mailadres",
      changeEmail_delivered:
        "Er is een e-mail gestuurd naar {{email}} met een bevestigingslink. Je bent nu uitgelogd en kunt deze pagina sluiten.",
      logout_newEmail: "Het e-mailadres is gewijzigd in {{email}}. Je kunt nu gelijk inloggen op Mijn CBG.",
      "First name is required": "Het veld Voornaam is verplicht",
      "Initials are required": "Het veld Voorletters is verplicht",
      "Last name is required": "Het veld Achternaam is verplicht",
      "Country is required": "Het veld Land is verplicht",
      "Housenumber is required": "Het veld Huisnummer is verplicht",
      "Only numbers": "Alleen cijfers",
      "Zipcode is required": "Het veld Postcode is verplicht",
      "Vereist formaat voor Nederland": 'Vereist formaat voor Nederland: "1234 AB"',
      "Street is required": "Het veld Straat is verplicht",
      "email in use": "Fout: Dit e-mailadres wordt al door een andere account gebruikt",
      UnexpectedLambdaException: "Door een technische storing lukt deze handeling nu even niet. Probeer het over enkele minuten nog eens.",
      familyname: "Familienaam",
      firstnames: "Voornamen",
      birthdate: "Geboortedatum",
      birthPlace: "Geboorteplaats",
      dateOfDeath: "Overlijdensdatum",
      placeOfDeath: "Overlijdensplaats",
      familynamePartner: "Familienaam huwelijks- of geregistreerd partner",
      spoedAanvraag: "Spoedaanvraag",
      spoedAanvraagSubtitle: "Aanvraag binnen 48 uur afhandelen (€ 12,25 per aangevraagde persoon)",
      spoed_aanvraag: "spoedAanvraag",
      couponcode: "Korting",
      termsDescriptionNro: "Ik ga akkoord met de <1>algemene voorwaarden</1>.",
      orderStatusSuccessNro: "Bedankt voor je bestelling.",
      orderStatusSuccessNro2:
        "We gaan met je aanvraag aan de slag. Dat duurt maximaal twee weken. Wanneer we klaar zijn ontvang je een e-mail met een instructie hoe je het resultaat kunt downloaden. Kijk alvast <1>hier<1> voor tips hoe je een persoonskaart of persoonslijst moet interpreteren. Je factuur staat klaar in ",
      combiFriendship: "CombiVriendschap",
      alreadyMember: "Ik ben al lid of donateur van:",
      association: "Mijn vereniging",
      membership: "Lid- of donateurnummer",
    },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "nl",
    // lng: 'en',
    debug: process.env.NODE_ENV !== "production" ? true : false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      // order and from where user language should be detected
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupQuerystring: "lang",
    },
  });

export default i18n;
