import React from 'react';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import {Greetings} from "aws-amplify-react";
import CustomSignOut from './CustomSignOut';
import AppBar from '@material-ui/core/AppBar';
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo-cbg-corporate-white.svg";
import Toolbar from "@material-ui/core/Toolbar";
import {Link} from "react-router-dom";

const styles = theme => ({
  main: {
    width: 'auto',
    minWidth: '600px',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    background: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    // marginLeft: theme.spacing(6),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

class CustomGreetings extends Greetings {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn"];
  }

  renderSignOutButton() {
    const stateAndProps = Object.assign({}, this.props, this.state);
    return <CustomSignOut {...stateAndProps} />;
  }

  render() {
    const {classes} = this.props;

    const authState = this.props.authState || this.state.authState;
    const signedIn = authState === 'signedIn';

    if(!signedIn) {
      return null;
    }

    return (
        <div className={classes.root}>
        <AppBar position="static"  className={classes.main}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <Link to="/" ><img src={logo} alt="CBG"/></Link>
            </Typography>
            {/*{this.renderSignOutButton()}*/}
          </Toolbar>
        </AppBar>
        </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(CustomGreetings));
