import React, { useEffect } from 'react';

import Amplify, { Auth } from 'aws-amplify';
import { parse } from 'query-string';
import awsconfig from '../aws-config-manual';
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const valid_origins = [
  'https://cbg.nl',
  'https://cbgverzamelingen.nl',
  'https://cbgbibliotheek.nl',
  'https://www.stamboomnederland.nl',
  'https://stamboomnederland.nl',
  'https://cbgfamiliewapens.nl',
  'https://www.cbgfamilienamen.nl',
  'https://cbgfamilienamen.nl',
  'https://wiewaswie.nl',
  'https://www.wiewaswie.nl',
  'https://cbg.voorbeeld.website',
  'https://test.cbg.nl',
  'http://test.cbg.nl',
  'https://web-app-cbg-prod.azurewebsites.net',
  'https://cbgbibliotheek.test7.picturae.pro',
  'https://cbgverzamelingen.test7.picturae.pro',
  'https://cbgfamiliewapens.test7.picturae.pro',
  'https://acceptatie.wiewaswie.nl'
];

function IFrame(props) {
  const {location} = props;
  const search = parse(location.search);

  useEffect(() => {
    async function postMessage() {
      const DEBUG = process.env.REACT_APP_DEV === 'true';
      if (search.origin && valid_origins.includes(search.origin)) {
        if(DEBUG) console.log(search.origin);
        let storageAccess = true;
        if(document.hasStorageAccess) {
          if(DEBUG) console.log('StorageAccessAPI available');
          storageAccess = await document.hasStorageAccess();
          if(!storageAccess) {
            if(document.requestStorageAccess) {
              if(DEBUG) console.log('Request StorageAccess');
              try {
                storageAccess = await document.requestStorageAccess();
              } catch(err) {
                storageAccess = false;
                if(DEBUG) console.log("Request denied");
              }
            }
          }
        }
        let storageAccessAmplify = null;
        try {
          storageAccessAmplify = window.localStorage.getItem("amplify-authenticator-authState");
        } catch (e) {}
        if(storageAccess && storageAccessAmplify !== null) {
          if(DEBUG) console.log('hasStorageAccess');
          try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const session = cognitoUser.getSignInUserSession();
            window.parent.postMessage(session.getIdToken(), search.origin);
          } catch(err) {
            if(DEBUG) console.log(err);
            if(DEBUG) console.log('send logout message');
            window.parent.postMessage({
              "jwtToken": null,
              "payload": {
                "subscriptions": "",
                "cbg.nl": "0",
                "cbgfamilienamen.nl": "0",
                "cbgfamiliewapens.nl": "0",
                "cbgbibliotheek.nl": "0",
                "cbgverzamelingen.nl": "0",
                "wiewaswie.nl": "0"
              }
            }, search.origin);
          }
        } else {
          if(DEBUG) console.log('no access to session');
        }
      }
    }
    postMessage();
  }, [search.origin]);

  return (
    <p>Loading...</p>
  );
}

export default IFrame;
