import React, {useEffect, useState} from 'react';

import Amplify from 'aws-amplify';
import {parse} from 'query-string';
import awsconfig from '../aws-config-manual';
import {useCode} from "../hooks/useCode";
import CssBaseline from "@material-ui/core/CssBaseline";
import {CircularProgress} from "@material-ui/core";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
Amplify.configure(awsconfig);

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

function CognitoAuthorize(props) {
  const {location, classes} = props;
  const search = parse(location.search);

  const [host, setHost] = useState('');
  const [code] = useCode();

  useEffect(() => {
    if (search.redirect_uri && code) {
      const url = new URL(search.redirect_uri);
      setHost(url.host);
      url.searchParams.append('code', code);
      url.searchParams.append('state', search.state);
      window.location.replace(url.href);
    }
  }, [code, search.redirect_uri, search.state]);

  return (
      <main className={classes.main}>
        <CssBaseline/>
        <div>
          <CircularProgress color="secondary" />
          { !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?  <span>Redirecting to: {host}</span> : null }
        </div>
      </main>
  );
}

CognitoAuthorize.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CognitoAuthorize);
