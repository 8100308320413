import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Amplify, { API, Auth } from "aws-amplify";
import awsconfig from "../aws-config-manual";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { DateUtils } from "@aws-amplify/core";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

// Auth.configure({
//   authenticationFlowType: 'USER_PASSWORD_AUTH'
// });

const styles = (theme) => ({
  layout: {
    width: "auto",
    margin: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2 * 2))]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  card: {
    // margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

function OrderNroStatus(props) {
  const { classes, match } = props;
  const { t } = useTranslation();
  const {
    params: { orderId },
  } = match;

  const [values, setValues] = useState({});
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function orderNroStatus() {
      if (true) {
        const apiName = "oAuth";
        const path = `/shop/statusnro/${orderId}`;
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
          const res = await API.get(apiName, path);
          cognitoUser.refreshSession(session.refreshToken);
          // console.log(res);
          setValues(res);
          setLoading(false);
        } catch (err) {
          console.error(err);
          setError(err);
          setLoading(false);
        }
      }
    }
    orderNroStatus();
  }, [orderId]);

  let message;
  if (values.status) {
    switch (values.status) {
      case "paid":
        message = (
          <div>
            <h1>{t("orderStatusSuccessNro")}</h1>
            {/* <Trans i18nKey="orderStatusSuccessNro2"> */}
            We gaan met je aanvraag aan de slag. Dat duurt maximaal twee weken, bij een spoedaanvraag maximaal twee werkdagen. Wanneer we
            klaar zijn ontvang je een e-mail met een instructie hoe je het resultaat kunt downloaden. Kijk alvast{" "}
            <a href="https://cbg.nl/bronnen/cbg-verzamelingen/nationaal-register-overledenen-nro" target="_blank" rel="noopener noreferrer">
              hier
            </a>{" "}
            voor tips hoe je een persoonskaart of persoonslijst moet interpreteren. Je factuur wordt je per mail toegestuurd.
          </div>
        );
        break;
      case "open":
        message = (
          <div>
            <h1>Bedankt voor uw bestelling.</h1>
            <h2>De betaling is nog niet afgerond.</h2>
            <p>
              <a href={values.checkoutUri}>
                <Button variant="contained" color="primary" shape="chubby">
                  Betalen met iDeal
                </Button>
              </a>
            </p>
          </div>
        );
        break;
      case "canceled":
      case "expired":
      case "failed":
      default:
        message = (
          //  <div>
          //   <h1>{t('orderStatusError')}</h1>
          //  </div>
          <div>
            <h1>{t("orderStatusSuccessNro")}</h1>
            {/* <Trans i18nKey="orderStatusSuccessNro2"> */}
            We gaan met je aanvraag aan de slag. Dat duurt maximaal twee weken. Wanneer we klaar zijn ontvang je een e-mail met een
            instructie hoe je het resultaat kunt downloaden. Kijk alvast{" "}
            <a href="https://cbg.nl/bronnen/cbg-verzamelingen/persoonskaarten-en-lijsten/" target="_blank" rel="noopener noreferrer">
              hier
            </a>{" "}
            voor tips hoe je een persoonskaart of persoonslijst moet interpreteren. Je factuur staat klaar in {/* </Trans> */}
            <a href="https://mijn.cbg.nl/" target="_blank" rel="noopener noreferrer">
              {" "}
              Mijn CBG
            </a>
          </div>
        );
        break;
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {isLoading ? (
            <CircularProgress className={classes.progress} />
          ) : error ? (
            <div>
              <h1>Error</h1>
              <p>{error.message}</p>
            </div>
          ) : (
            message
          )}
        </Paper>
        {!isLoading && values.status === "paid" ? (
          <Paper>
            <p>
              <Button variant="contained" color="primary" shape="chubby" href={process.env.REACT_APP_CBG_URI}>
                {t("Terug naar CBG.nl")}
              </Button>
            </p>
          </Paper>
        ) : null}
        {!isLoading && values.status !== "paid" ? (
          <Paper>
            <p>
              <Button variant="contained" color="primary" shape="chubby" href={t("signup_info_link")}>
                {t("Terug naar de bestelpagina")}
              </Button>
            </p>
          </Paper>
        ) : null}
        {process.env.NODE_ENV === "development" ? (
          <Paper>
            <pre>{`Values: ${JSON.stringify(values, null, 4)}`}</pre>
          </Paper>
        ) : null}
        {process.env.NODE_ENV === "development" ? (
          <Paper>
            <pre>{`Error: ${JSON.stringify(error, null, 4)}`}</pre>
          </Paper>
        ) : null}
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(OrderNroStatus);
