import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Amplify, {API} from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {Redirect} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import logo from "../images/logo-cbg-corporate.svg";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
            width: 550,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
});

function VerifyEmail(props) {
    const { t } = useTranslation();
    const { classes, match } = props;
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [newEmail, setNewEmail] = useState(undefined);

    useEffect(() => {
        async function submitCode() {
            if (match.params && match.params.sub && match.params.code) {
                const { sub, code } = match.params;
                const apiName = 'oAuth';
                const path = '/verifyNewEmail';
                const body = { sub, code };
                try {
                    const res = await API.post(apiName, path, { body });
                    console.log(res);
                    setNewEmail(res.email);
                    setLoading(false);
                } catch(err) {
                    setError('Invalid link');
                    setLoading(false);
                }
            }
        }
        submitCode();
    }, [match]);

    if (isLoading) {
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h1">
                        <img style={{width: '100%'}} src={logo} alt="CBG"/>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {t('verification')}
                    </Typography>
                    <CircularProgress />
                </Paper>
            </main>
        );
    } else {
        if (error === undefined) {
            return (
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Typography variant="h1">
                            <img style={{width: '100%'}} src={logo} alt="CBG"/>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {t('verification')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            U kunt opnieuw inloggen.
                        </Typography>
                        { newEmail ? <Redirect push to={{ pathname: '/logout', state: { email: newEmail }}} /> : null}
                    </Paper>
                </main>
            );

        } else {
            return (
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Typography variant="h1">
                            <img style={{width: '100%'}} src={logo} alt="CBG"/>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {t('verification')}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Error: {error}
                        </Typography>
                    </Paper>
                </main>
            );

        }
    }
}

VerifyEmail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VerifyEmail);
