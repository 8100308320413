import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";

function calcActivePersons(totalPersons) {
	let entries = Object.entries(totalPersons);

	// console.log(entries);

	let group = _.groupBy(entries, function (b) {
		return b[0].split("-")[b[0].split("-").length - 1];
	});

	// console.log(group);

	let persons = Object.keys(group).map((personIndex) => {
		let valuesArray = group[personIndex];
		let person = {
			id: personIndex,
		};
		valuesArray.forEach((vArray) => {
			person[vArray[0].replace(`-${personIndex}`, "")] = vArray[1];
		});
		return person;
	});

	// console.log(persons);

	// console.log(
	//   persons.filter((p) => {
	//     return p.active === true;
	//   })
	// );

	persons = persons.filter((person) => person.active);

	return persons.length;
}

function calculatePriceData(pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons) {
	let discountPrice = { discountType: ``, value: 0.0 };
	let personPrijs = 0;
	let personPrijsTotaal = 0;
	let aanvraagPrijs = 0;

	if (pricelist.length === 0) {
		return { discountPrice: discountPrice, personPrijs, personPrijsTotaal };
	}

	//  if (values.spoedAanvraag) {
	//   aanvraagPrijs = pricelist[1].Verkoopprijs;
	//   setAanvraagPrijs(pricelist[1].Verkoopprijs);
	//  } else {
	//   aanvraagPrijs = pricelist[0].Verkoopprijs1;
	//   setAanvraagPrijs(pricelist[0].Verkoopprijs1);
	//  }

	// console.log("inside calculateprice");
	// console.log({ personValues });
	// console.log({ totalPersons });

	let aantalPersonen = calcActivePersons(totalPersons);
	// aantalPersonen = 101;
	// console.log({ aantalPersonen });

	//  let aantalPersonen = calculatePersonLength(personValues);
	let eindprijs = pricelist[0].Verkoopprijs1 * 2;
	let discountPriceValue = 0.0;
	// console.log("aantal personen: " + aantalPersonen);
	if (discount && discount.value) {
		// console.log("inside discount");
		// aantalPersonen = aantalPersonen - discount.value;
		if (values.spoedAanvraag) {
			discountPriceValue = discount.value * pricelist[1].Verkoopprijs;
		} else {
			discountPriceValue = discount.value * pricelist[0].Verkoopprijs;
		}

		discountPrice = { discountType: `${discount.value} aanvragen`, value: discountPriceValue };

		// console.log("aantal personen after discount" + aantalPersonen);

		// console.log("aantalPersonen before eindprijs calc: " + aantalPersonen);

		if (aantalPersonen === 0) {
			// console.log("inside 0 personen");
			eindprijs = 0.0;
		} else if (aantalPersonen > 0 && aantalPersonen <= 2) {
			// console.log("inside 0 -> 2");
			if (values.spoedAanvraag) {
				eindprijs = pricelist[1].Verkoopprijs;
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
				personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[1].Verkoopprijs);
			} else {
				eindprijs = pricelist[0].Verkoopprijs1;
				aanvraagPrijs = pricelist[0].Verkoopprijs1;
				setAanvraagPrijs(pricelist[0].Verkoopprijs1);
				personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[0].Verkoopprijs1);
			}
		} else if (aantalPersonen > 2 && aantalPersonen <= 100) {
			// console.log("2->100");
			if (values.spoedAanvraag) {
				eindprijs = aantalPersonen * pricelist[1].Verkoopprijs;
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
				personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[1].Verkoopprijs);
			} else {
				if (pricelist[0].Hoeveelheid3 === 3) {
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs3;
					setAanvraagPrijs(pricelist[0].Verkoopprijs3);
					personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[0].Verkoopprijs3);
				} else {
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs2;
					aanvraagPrijs = pricelist[0].Verkoopprijs2;
					setAanvraagPrijs(pricelist[0].Verkoopprijs2);
					personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[0].Verkoopprijs2);
				}
			}
			// console.log()
		} else if (aantalPersonen > 100) {
			// console.log("100+");
			if (values.spoedAanvraag) {
				eindprijs = aantalPersonen * pricelist[1].Verkoopprijs;
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
				personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[1].Verkoopprijs);
			} else {
				if (pricelist[0].Hoeveelheid4) {
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs4;
					aanvraagPrijs = pricelist[0].Verkoopprijs4;
					setAanvraagPrijs(pricelist[0].Verkoopprijs4);
					personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[0].Verkoopprijs4);
				} else {
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs3;
					setAanvraagPrijs(pricelist[0].Verkoopprijs3);
					personPrijs = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(pricelist[0].Verkoopprijs3);
				}
			}
		}
		// console.log(eindprijs);
		personPrijs = eindprijs;
		personPrijsTotaal = eindprijs - discountPriceValue > 0 ? eindprijs - discountPriceValue : 0;
		setPersonPrijsTotal(eindprijs - discountPriceValue > 0 ? eindprijs - discountPriceValue : 0);
	} else {
		// console.log("aantalPersonen before eindprijs calc: " + aantalPersonen);
		if (aantalPersonen === 0) {
			// console.log("inside 0 personen");
			eindprijs = 0.0;
		} else if (aantalPersonen > 0 && aantalPersonen <= 2) {
			// console.log("inside 0 -> 2");
			aanvraagPrijs = pricelist[0].Verkoopprijs1;

			if (values.spoedAanvraag) {
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
				eindprijs = aantalPersonen * pricelist[1].Verkoopprijs;
			} else {
				aanvraagPrijs = pricelist[0].Verkoopprijs1;
				setAanvraagPrijs(pricelist[0].Verkoopprijs1);
				eindprijs = pricelist[0].Verkoopprijs1;
			}
		} else if (aantalPersonen > 2 && aantalPersonen <= 100) {
			// console.log("2->100");
			if (values.spoedAanvraag) {
				eindprijs = aantalPersonen * pricelist[1].Verkoopprijs;
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
			} else {
				if (pricelist[0].Hoeveelheid3 === 3) {
					// eindprijs = prijzen.Verkoopprijs3 + (aantalPersonen - 2) * prijzen.Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs3;
					setAanvraagPrijs(pricelist[0].Verkoopprijs3);
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs3;
					// setPersonPrijsTotal(eindprijs);
				} else {
					// eindprijs = prijzen.Verkoopprijs3 + (aantalPersonen - 2) * prijzen.Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs2;
					setAanvraagPrijs(pricelist[0].Verkoopprijs2);
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs2;
					// setPersonPrijsTotal(eindprijs);
				}
			}
			// console.log()
		} else if (aantalPersonen > 100) {
			// console.log("100+");
			if (values.spoedAanvraag) {
				eindprijs = aantalPersonen * pricelist[1].Verkoopprijs;
				aanvraagPrijs = pricelist[1].Verkoopprijs;
				setAanvraagPrijs(pricelist[1].Verkoopprijs);
			} else {
				if (pricelist[0].Hoeveelheid4) {
					// eindprijs = prijzen.Verkoopprijs3 + (aantalPersonen - 2) * prijzen.Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs4;
					setAanvraagPrijs(pricelist[0].Verkoopprijs4);
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs4;
					// setPersonPrijsTotal(eindprijs);
				} else {
					// eindprijs = prijzen.Verkoopprijs3 + (aantalPersonen - 2) * prijzen.Verkoopprijs3;
					aanvraagPrijs = pricelist[0].Verkoopprijs3;
					setAanvraagPrijs(pricelist[0].Verkoopprijs3);
					eindprijs = aantalPersonen * pricelist[0].Verkoopprijs3;
					// setPersonPrijsTotal(eindprijs);
				}
			}
		}
		// console.log(eindprijs);
		discountPrice = { discountType: ``, value: 0.0 };
		personPrijs = eindprijs;
		personPrijsTotaal = eindprijs;
		setPersonPrijsTotal(personPrijsTotaal);
	}

	// console.log("before return");
	// console.log(aanvraagPrijs);

	return { discountPrice: discountPrice, personPrijs, personPrijsTotaal, aanvraagPrijs };
}

function NroTotals({
	classes,
	personValues,
	discount,
	setDiscount,
	//  aanvraagPrijs,
	setAanvraagPrijs,
	//  personPrijsTotaal,
	setPersonPrijsTotal,
	pricelist,
	totalPersons,
	...otherProps
}) {
	// console.log("nrototals");

	// console.log({ classes, personValues, discount, setDiscount, setAanvraagPrijs, pricelist });

	const values = personValues;

	const { discountPrice, personPrijs, personPrijsTotaal, aanvraagPrijs } = useMemo(
		() => calculatePriceData(pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons),
		[pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons]
	);

	useEffect(() => {
		async function changePrice(spoedAanvraag, couponcode, type, pricelist) {
			let aantalPersonen = calcActivePersons(totalPersons);
			// console.log("changeprice");
			// console.log({ aantalPersonen });

			if (couponcode && type) {
				// console.log(couponcode);
				// console.log(type);
				if (couponcode.length > 30) {
					if (type === "4") {
						setDiscount({ code: couponcode, value: Number(type) });
						// console.log(discount);
					} else if (type === "10") {
						setDiscount({ code: couponcode, value: Number(type) });
						// console.log(discount);
					} else {
						setDiscount({});
					}
				} else {
					setDiscount({});
				}
			} else {
				setDiscount({});
			}
		}
		changePrice(values.spoedAanvraag, values.couponcode, values.type, pricelist);
	}, [values.spoedAanvraag, values.couponcode, values.type]);

	// console.log({ aanvraagPrijs });

	return (
		<Paper fullwidth="true" className={classes.paper}>
			<Grid item xs={12}>
				<Typography variant="h6" gutterBottom={true}>
					Bestelling
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}></Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}>
						Per aanvraag
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}>
						Totaal
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						Aantal NRO-uittreksels: {calcActivePersons(totalPersons)}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(aanvraagPrijs)}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(personPrijs)}
					</Typography>
				</Grid>
			</Grid>
			{discountPrice.discountType && (
				<>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="h6" gutterBottom={true}>
								Korting
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						{/** Per couponcode uitbreiden? */}
						<Grid item xs={12} sm={8}>
							<Typography variant="body2" align="left" gutterBottom={true}>
								{/* 644F5D4A-1D18-4BDD-BAB8-EA818DDEFE4D */}
								{discountPrice.discountType ? discountPrice.discountType : ""}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant="body2" align="left" gutterBottom={true}>
								{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(-discountPrice.value)}
							</Typography>
						</Grid>
					</Grid>
				</>
			)}
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom={true}>
						Totaal
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2" align="left" gutterBottom={true}></Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(personPrijsTotaal)}
					</Typography>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Paper>
	);
}

export default NroTotals;
