import React from 'react';
import { withTranslation } from 'react-i18next';
import {SignOut} from "aws-amplify-react";
import Button from "@material-ui/core/Button";

class CustomSignOut extends SignOut {
  render() {
    const {t} = this.props;

    // const authState = this.props.authState || this.state.authState;
    // const signedIn = authState === 'signedIn';

    return (
      <Button color="secondary" variant="contained" onClick={this.signOut}>{t('signout')}</Button>
    );
  }
}

export default withTranslation()(CustomSignOut);
