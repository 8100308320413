/* eslint-disable react/jsx-no-target-blank */

import React from 'react';

import Amplify from 'aws-amplify';
import {useCode} from "../hooks/useCode";
import awsconfig from '../aws-config-manual';
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation, withTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
Amplify.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    // width: 'auto',
    // display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3*2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing(8),
    marginTop: theme.spacing(8),
    margin: "auto",
    backgroundColor: 'rgba(74, 74, 74, 0.85)',
    color: "#ffffff"
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  gridItem: {
    textAlign: 'center'
  },
  buttons: {
    fontSize: "18px",
    fontFamily: [
      'alwynnelight',
      'serif',
    ].join(','),
    fontWeight: 300,
    color: '#ffffff',
    // minWidth: '150px',
    boxShadow: 'none',
    border: '1px solid #A59567',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    height: '40px'
  },
  label: {
    padding: '0 0px 0 16px',
    '&::before': {
      position: 'absolute',
      left: '12px',
      bottom: '1px',
      content: "'\\e900'",
      fontFamily: 'icomoon',
      speak: 'none',
      fontStyle: 'normal',
      fontWeight: 400,
      fontVariant: 'normal',
      textTransform: 'none',
      lineHeight: 1,
    },
  },
  my: {
    backgroundColor: '#4A90E2',
    borderColor: '#4A90E2',
  },
  cbg: {
    backgroundColor: '#A59567',
    borderColor: '#A59567',
  },
  collections: {
    backgroundColor: '#7457AA',
    borderColor: '#7457AA'
  },
  library: {
    backgroundColor: '#EC8035',
    borderColor: '#EC8035'
  },
  pedigrees: {
    backgroundColor: '#2D9758',
    borderColor: '#2D9758'
  },
  coatOfArms: {
    backgroundColor: '#BF4D59',
    borderColor: '#BF4D59'
  },
  surnames: {
    backgroundColor: '#3D6AC7',
    borderColor: '#3D6AC7'
  },
  whoWasWho: {
    backgroundColor: '#9B9B9B',
    borderColor: '#9B9B9B'
  }
}));


function CognitoApp(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [code, session] = useCode();

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant={"h2"}>
                CBG Bronnen
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href={code ? `${process.env.REACT_APP_OUTSITE_URI}/oauthcallback/custom?code=${code}&state=%2f` : '#'} target="_blank" rel="noopener">
                <Button variant="contained" disabled={code === null} classes={{label: classes.label}} className={clsx(classes.buttons, classes.my)}>Mijn CBG</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://cbg.nl" target="_blank" rel="noopener">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.cbg)}>CBG.nl</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://cbgverzamelingen.nl" target="_blank" rel="noopener">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.collections)}>Verzamelingen</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://cbgbibliotheek.nl/" target="_blank">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.library)}>Bibliotheek</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://stamboomnederland.nl/" target="_blank">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.pedigrees)}>Stambomen</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://cbgfamiliewapens.nl/" target="_blank">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.coatOfArms)}>Familiewapens</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://cbgfamilienamen.nl/" target="_blank">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.surnames)}>Familienamen</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href="https://wiewaswie.nl/" target="_blank">
                <Button variant="contained" className={clsx(classes.buttons, classes.whoWasWho)}>WieWasWie</Button>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <a href={t('signup_info_link')} target="_blank">
                <Button variant="contained" classes={{label: classes.label}} className={clsx(classes.buttons, classes.cbg)}>{t('order')}</Button>
              </a>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4">
            {t('confirmSignUp')}
          </Typography>
          <Typography variant="body1">
            {t('confirmUserBody')}
          </Typography>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Geen e-mail ontvangen?
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Button
                  type="button"
                  fullWidth
                  color="primary"
              >
                {t('resendCode')}
              </Button>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>

          {
          process.env.NODE_ENV === 'development'
              ? <Paper className={classes.paper}>
                  <p><a href={`${process.env.REACT_APP_API_ENDPOINT_OAUTH}/oauth2/auth?client_id=uxldyanLQuz7rp1V44BaSsU0&redirect_uri=${process.env.REACT_APP_OUTSITE_URI}%2foauthcallback%2fcustom&response_type=code&state=%2f`}>Open het klantportaal</a></p>
                  <pre>
                    Token: {session.idToken && session.idToken.payload ? JSON.stringify(session.idToken.payload, null, 4) : ''}{'\n'}
                  </pre>
                </Paper>
              : null
        }
      </main>
    </div>
  );
}


export default withTranslation()(CognitoApp);