import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {useTranslation} from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {SnackbarContent} from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import { amber, green } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  dialog: {

  }
}));

export function ErrorDialog(props) {
  const classes = useStyles();
  const {onClose, open, error} = props;
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
      <Dialog onClose={handleClose} open={open} BackdropProps={{invisible: true}}>
        <SnackbarContent
            className={classes.warning}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
          <WarningIcon className={clsx(classes.icon, classes.iconVariant)} />
                {t(error.name)}
        </span>
            }
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
        />
      </Dialog>
  )
}