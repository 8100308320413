import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CognitoApp from './components/CognitoApp';

import { ConfirmSignIn, RequireNewPassword, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';

import CustomSignIn from './components/CustomSignIn';
import CustomSignUp from './components/CustomSignUp';
import CustomGreetings from './components/CustomGreetings';
import CustomForgotPassword from './components/CustomForgotPassword';
import CustomConfirmSignUp from './components/CustomConfirmSignUp';
import MyTheme from './components/MyTheme';

import CognitoAuthorize from './components/CognitoAuthorize';
import Order from './components/Order';
import OrderStatus from './components/OrderStatus';
import OrderNro from './components/OrderNro';
import OrderNroStatus from './components/OrderNroStatus';

import Amplify from 'aws-amplify';
import awsconfig from './aws-config-manual';
import ChangeEmail from './components/ChangeEmail';
import ChangePassword from './components/ChangePassword';
import CBGButtons from './components/CBGButtons';
import Status from './components/Status';

Amplify.configure(awsconfig);

function Routes(props) {
 return (
  <Switch>
   <Route exact path="/authorize" component={CognitoAuthorize} />
   <Route exact path="/register" component={SignUp} />
   <Route exact path="/change_email" component={ChangeEmail} />
   <Route exact path="/change_password" component={ChangePassword} />
   {/*<Route exact path='/login' component={ CognitoSignIn } />*/}
   {/*<Route path='/verify/:sub/:code' component={ Verification } />*/}
   {/*<Route exact path='/cognito' component={ CognitoApp } />*/}
   <Route path="/shop/order/:orderId" component={OrderStatus} />
   <Route path="/shop/ordernro/:orderId" component={OrderNroStatus} />
   <Route path="/shop/nro" component={OrderNro} />
   <Route path="/shop/:productId" component={Order} />
   <Route exact path="/buttons" component={CBGButtons} />
   <Route exact path="/status" component={Status} />
   <Route exact path="/" component={CognitoApp} />
  </Switch>
 );
}

export default withAuthenticator(Routes, {
 includeGreetings: true,
 authenticatorComponents: [
  <CustomGreetings />,
  <CustomSignIn />,
  <CustomSignUp />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <CustomConfirmSignUp />,
  <CustomForgotPassword />,
  <RequireNewPassword />,
 ],
 theme: MyTheme,
});
