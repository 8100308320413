import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Divider, FormControl, MenuItem, FormLabel, Radio, Button, InputLabel, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff, Check, Warning } from "@material-ui/icons";
import { TextField, Checkbox, RadioGroup, Select } from "formik-material-ui";
import { Field, Form } from "formik";
import landCodes from "../landCodes";
import axios from "axios";
import { DateUtils } from "@aws-amplify/core";
import Amplify, { API, Auth } from "aws-amplify";

function PersonForm(props) {
  const { values, touched, setFieldValue, setTouched, formVariant, handleBlur } = props;
  const { zipCode, houseNumber } = values;
  const [showPassword, setShowPassword] = useState(false);
  const [lookupAddress, setLookupAddress] = useState(false);
  const [lookupSuccessful, setLookupSuccessful] = useState(true);
  const [cache, updateCache] = useState({});
  const { t } = useTranslation();

  const [association, setAssociation] = useState([]);

  useEffect(() => {
    async function getAssociations() {
      if (values.productId === 9) {
        const apiName = "oAuth";
        const path = `/shop/association`;
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);

          const res = await API.get(apiName, path);
          // console.log(res);
          setAssociation(res);
        } catch (e) {
          console.log(e);
        }
      }
    }

    getAssociations();
  }, [values.productId]);

  useEffect(() => {
    function updateAddress(street, city) {
      setLookupAddress(false);
      setFieldValue("street", street);
      setFieldValue("city", city);
      setTouched({ ...touched, street: true, city: true });
      setLookupSuccessful(true);
    }
    if (lookupAddress) {
      if (touched.zipCode && touched.houseNumber && /^[0-9]{4} ?[a-zA-Z]{2}$/.exec(zipCode) && /^[0-9]+$/.exec(houseNumber)) {
        const key = `${zipCode}_${houseNumber}`;
        if (cache[key]) {
          const doc = cache[key];
          updateAddress(doc.straatnaam, doc.woonplaatsnaam);
        } else {
          async function lookup() {
            try {
              const res = await axios.get(
                `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?q=postcode:${zipCode.replace(
                  " ",
                  ""
                )}+AND+huisnummer:${houseNumber}&fq=type:adres&fl=straatnaam,woonplaatsnaam&rows=1&wt=json`
              );
              if (res.data && res.data.response && res.data.response.numFound && res.data.response.numFound > 0) {
                const doc = res.data.response.docs[0];
                updateCache({ ...cache, [key]: doc });
                updateAddress(doc.straatnaam, doc.woonplaatsnaam);
              } else {
                setLookupAddress(false);
                setLookupSuccessful(false);
              }
            } catch (err) {
              console.error(err);
              setLookupAddress(false);
              setLookupSuccessful(false);
            }
          }
          lookup();
        }
      }
    }
  }, [lookupAddress, zipCode, houseNumber, setFieldValue, touched, setTouched, setLookupSuccessful, cache, updateCache]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            <Trans i18nKey={"requiredFields"}>
              Velden met een <span className="MuiFormLabel-asterisk">*</span> zijn verplicht
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {formVariant === "signUp" ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Account
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} required name="username" label={t("email")} fullWidth autoComplete="email" type="email" />
            </Grid>
            <Grid item xs={10}>
              <Field
                component={TextField}
                type={showPassword ? "text" : "password"}
                required
                name="password"
                label={t("password")}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t("passwordRequirements")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t("personalInformation")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field component={TextField} required name="firstName" label={t("firstName")} fullWidth autoComplete="given-name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            required={formVariant === "order"}
            values={values.initials}
            name="initials"
            label={t("initials")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field component={TextField} name="lastNamePrefix" label={t("prefix")} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field component={TextField} required name="lastName" label={t("lastName")} fullWidth autoComplete="family-name" />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("gender")}</FormLabel>
            <Field component={RadioGroup} aria-label="gender" name="gender" row={true}>
              <FormControlLabel value="male" control={<Radio />} label={t("male")} />
              <FormControlLabel value="female" control={<Radio />} label={t("female")} />
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t("contactDetails")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required={formVariant === "order"}>
            <InputLabel htmlFor="country">{t("country")}</InputLabel>
            <Field
              component={Select}
              name="country"
              fullWidth
              native
              autoComplete="country"
              inputProps={{
                id: "country",
              }}
            >
              <option key="_" aria-label="" value="" disabled></option>
              <option key="_NL" value="NL">
                Nederland
              </option>
              <option key="_B" value="B">
                België
              </option>
              <option key="_D" value="D">
                Duitsland
              </option>
              <option key="_F" value="F">
                Frankrijk
              </option>
              <option key="_GB" value="GB">
                Verenigd Koninkrijk
              </option>
              <option key="_AUS" value="AUS">
                Australië
              </option>
              <option key="_CDN" value="CDN">
                Canada
              </option>
              <option key="_USA" value="USA">
                Verenigde Staten
              </option>
              <option key="--" value="--" disabled>
                --
              </option>
              {landCodes.map((l) => (
                <option key={l.Code_land} value={l.Code_land}>
                  {l.Land}
                </option>
              ))}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            component={TextField}
            name="zipCode"
            label={t("zipcode")}
            required={formVariant === "order"}
            fullWidth
            autoComplete="postal-code"
            InputProps={{
              onBlur: (e) => {
                handleBlur(e);
                setLookupAddress(true);
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field
            component={TextField}
            name="houseNumber"
            label={t("houseNumber")}
            required={formVariant === "order"}
            fullWidth
            InputProps={{
              onBlur: (e) => {
                handleBlur(e);
                setLookupAddress(true);
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Field component={TextField} name="addition" label={t("houseNumberAddition")} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            name="street"
            label={t("street")}
            required={formVariant === "order"}
            fullWidth
            autoComplete="street-address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {lookupSuccessful ? values.street !== "" ? <Check style={{ color: green[500] }} /> : null : <Warning color="error" />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="city"
            label={t("city")}
            required={formVariant === "order"}
            fullWidth
            autoComplete="address-level2"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {lookupSuccessful ? values.city !== "" ? <Check style={{ color: green[500] }} /> : null : <Warning color="error" />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field component={TextField} name="phone" label={t("phone")} fullWidth autoComplete="tel" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {formVariant === "order" && props.values.productId === 9 ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t("combiFriendship")}
              </Typography>
              <Typography variant="body1">{t("alreadyMember")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel htmlFor="association">{t("association")}</InputLabel>
                <Field component={Select} name="association" label={t("association")} fullWidth autoComplete="association">
                  {association.map((l) => (
                    <MenuItem key={l.Code} value={l.Code}>
                      {l.Omschrijving}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field component={TextField} required name="membership" label={t("membership")} fullWidth autoComplete="membership" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t("newsLetter")}
          </Typography>
          {/* <Typography variant="body1">{t("newsLetterSubtitle")}</Typography> */}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel control={<Field component={Checkbox} name="newsCbg" type="checkbox" />} label={t("news_cbg")} />
        </Grid>
        {formVariant === "order" ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t("terms")}
              </Typography>
              {/* <Typography variant="body1">
                <Trans i18nKey={"termsDescription"}>
                  Gaat u akkoord met de{" "}
                  <a href="https://cbg.nl/algemene-voorwaarden" target="_blank" rel="noopener noreferrer">
                    algemene voorwaarden
                  </a>
                  ? Dit is noodzakelijk om een abonnement te bestellen.
                </Trans>
              </Typography> */}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Field component={Checkbox} name="terms" required type="checkbox" />}
                label={
                  <Trans i18nKey={"termsDescription"}>
                    Gaat u akkoord met de{" "}
                    <a href="https://cbg.nl/algemene-voorwaarden" target="_blank" rel="noopener noreferrer">
                      algemene voorwaarden
                    </a>
                    ? Dit is noodzakelijk om een abonnement te bestellen.
                  </Trans>
                }
              />
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button fullWidth type="submit" variant="contained" color="primary">
          {formVariant === "order" ? t("pay") : t("signup_action")}
        </Button>
      </div>
    </Form>
  );
}

export default PersonForm;
