import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

import Amplify from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(450 + theme.spacing(3 * 2))]: {
            width: 450,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
});

function Verification(props) {
    const { t } = useTranslation();
    const { classes, match } = props;

    if (match.params && match.params.sub && match.params.code) {
        const { sub, code } = match.params;
        const userPool = new CognitoUserPool({UserPoolId: awsconfig.Auth.userPoolId, ClientId: awsconfig.Auth.userPoolWebClientId});
        const user = new CognitoUser({Username: sub, Pool: userPool});
        console.log(user);
        user.confirmRegistration(code, false, (err, res) => {
            if(err) {
                console.log(err);
            } else {
                console.log(res);
                // window.location.replace(`${process.env.REACT_APP_API_ENDPOINT_OAUTH}/oauth2/auth?client_id=uxldyanLQuz7rp1V44BaSsU0&redirect_uri=${process.env.REACT_APP_OUTSITE_URI}%2foauthcallback%2fcustom&response_type=code&state=%2f`);
            }
        });

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                        {t('verification')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <Trans i18nKey={"verification_body"}>
                            Bedankt voor het afronden van de registratie. Je kunt nu gelijk inloggen op <a href='/'>Mijn CBG</a>
                        </Trans>
                    </Typography>
                </Paper>
            </main>
        );
    } else {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {t('verification')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Per e-mail krijgt u een verificatielink.
                </Typography>

                {/*<Grid container spacing={2}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <Typography variant="body1" gutterBottom className={classes.title}>*/}
                {/*            Voer hier de verificatiecode in die u per e-mail heeft gekregen*/}
                {/*        </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item container direction="column" xs={12}>*/}
                {/*        <TextField required id="verify" label="Verificatiecode" type="number" fullWidth />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </React.Fragment>
        );
    }
}

Verification.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Verification);
