import React from 'react';
import {Trans, withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import {SignIn} from "aws-amplify-react";
import {withRouter} from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo-cbg-corporate.svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import {ErrorDialog} from "./ErrorDialog";
import Auth from "@aws-amplify/auth";

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3*2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginRight: theme.spacing(3),
  }
});

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {open: false, error: {}}
  }

  register() {
    withRouter(({history}) => {
      history.push('/register');
    });
    this.changeState('signUp');
  }

  forgotPassword() {
    this.changeState('forgotPassword');
  }

  error(err) {
    // console.log(err);
    this.setState({error: err, open: true });
    super.error(err);
  }

  changeState(state, data) {
    console.log('CustomSignIn.changeState(state=%j,data=%j)', state, data);
    if(state === 'confirmSignUp') {
      super.changeState(state, { ...data, resend: true});
    } else if(state === 'forgotPassword' && data && data.username) {
      Auth.forgotPassword(data.username).then(() => {
        super.changeState(state, data);
      })
    } else {
      if(state === 'signedIn') {
        console.log(data);
        if(data?.signInUserSession?.idToken?.payload?.change_address === 'true') {
          window.location.replace(process.env.REACT_APP_API_ENDPOINT_OAUTH + '/r/aanpassen-persoonsgegevens');
          return;
        }
      }
      super.changeState(state, data);
    }

  }

  showComponent() {
    const {classes, t} = this.props;
    return (
        <main className={classes.main}>
          <CssBaseline/>
          <Paper className={classes.paper}>
            <Typography variant="h1">
              <img style={{width: '100%'}} src={logo} alt="CBG"/>
            </Typography>
            <Typography component="h2" variant="h4">
              {t('signin')}
            </Typography>
            <Typography variant="subtitle1">
              {t('signin_subtitle')}
            </Typography>
            <form className={classes.form} onSubmit={(e) => super.signIn(e)}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{t('email')}</InputLabel>
                <Input id="username" type="email" name="username" autoComplete="email" onChange={this.handleInputChange} autoFocus/>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{t('password')}</InputLabel>
                <Input name="password" type="password" id="password" onChange={this.handleInputChange}
                       autoComplete="current-password"/>
              </FormControl>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                  className={classes.submit}
              >
                {this.state.loading ? <CircularProgress className={classes.progress} /> : null}
                {t('signin')}
              </Button>
              <Button
                  type="button"
                  fullWidth
                  color="primary"
                  disabled={this.state.loading}
                  onClick={(e) => this.forgotPassword()}
              >
                {t('forgotPassword')}?
              </Button>
            </form>
          </Paper>
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h4">
              {t('signup')}
            </Typography>
            <Typography variant="subtitle1" style={{marginBottom: '1em'}}>
              <Trans i18nKey={"signup_subtitle"}>
                Nieuwe gebruiker? <a href={t('signup_info_link')} target="_blank" rel="noopener noreferrer">voordelen</a>.
              </Trans>
            </Typography>
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={this.state.loading}
                onClick={(e) => this.register()}
            >
              {t('signup_action')}
            </Button>
          </Paper>
          <ErrorDialog open={this.state.open} error={this.state.error} onClose={() => this.setState({open: false})} />
        </main>
    );
  }
}

export default withTranslation()(withStyles(styles)(CustomSignIn));
