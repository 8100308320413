import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Amplify, {API, Auth} from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../images/logo-cbg-corporate.svg";
import {ErrorDialog} from "./ErrorDialog";
import {Redirect} from "react-router-dom";
import {DateUtils} from "@aws-amplify/core";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
            width: 550,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
});

function ChangeEmail(props) {
    const { t } = useTranslation();
    const { classes } = props;
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState();
    const [newEmail, setNewEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({});

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        const apiName = 'oAuth';
        const path = `/changeEmail`;
        const body = {
            newEmail
        };
        // console.log(body);
        Auth.currentAuthenticatedUser()
          .then(cognitoUser => {
              const session = cognitoUser.getSignInUserSession();
              DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
              API.post(apiName, path, {body})
                .then(res => {
                    setLoading(false);
                    setError(false);
                    setRedirect('/logout')
                })
                .catch(err => {
                    setLoading(false);
                    setError({name: err.response.data.message});
                    setOpen(true);
                });
          })
          .catch(err => {
              setLoading(false);
              setError({name: err.response.data.message});
              setOpen(true);
          });
    };

    return (
        <main className={classes.main}>
            <CssBaseline/>
            <Paper className={classes.paper}>
                <Typography variant="h1">
                    <img style={{width: '100%'}} src={logo} alt="Logo"/>
                </Typography>
                <Typography component="h1" variant="h4">
                    {t('changeEmail')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {t('changeEmailBody')}
                </Typography>
                <form className={classes.form} onSubmit={(e) => submit(e)}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">{t('newEmail')}</InputLabel>
                        <Input
                            name="email"
                            type="email"
                            id="email"
                            autoComplete="email"
                            onChange={e => setNewEmail(e.target.value)}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submit}
                    >
                        {loading ? <CircularProgress className={classes.progress} /> : null}
                        {t('changeEmail')}
                    </Button>
                </form>
                <ErrorDialog open={open} error={error} onClose={() => { setOpen(false); setLoading(false) }} />
                { redirect ? <Redirect push to={{ pathname: redirect, state: { newEmail }}} /> : null}
            </Paper>
        </main>
    );
}

ChangeEmail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangeEmail);
