import React from 'react';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import {ForgotPassword} from "aws-amplify-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo-cbg-corporate.svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3*2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginRight: theme.spacing(3),
  }
});

class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  error(err) {
    // this.setState({delivery: true });
    console.log(err);
    switch (err.code) {
      case 'LimitExceededException':
        this.setState({ delivery: { error: err.code } });
        break;
      case 'UserNotConfirmedException':
      case 'InvalidParameterException':
        const { authData = {} } = this.props;
        const username = this.getUsernameFromInput() || authData.username;
        this.changeState('confirmSignUp', {username, resend: true});
        this.setState({delivery: false });
        break;
      case 'UserNotFoundException':
        this.setState({ delivery: { error: err.code } });
        break;
      default:
        this.setState({ delivery: { error: err.code } });
        break;
    }
    super.error(err);
  }

  signIn() {
    this.changeState('signIn');
    this.setState({delivery: false });
  }

  showComponent() {
    const {authData, classes, t} = this.props;
    console.log('props: %j', this.props);
    console.log('state: %j', this.state);
    console.log('inputs: %j', this.inputs);
    const email = this.getUsernameFromInput() || (authData && authData.username)  || 'email';
    if(this.state.delivery) {
      console.log('case 3');
    } else {
      console.log('case 4');
    }

    return (
        <main className={classes.main}>
          <CssBaseline/>
          <Paper className={classes.paper}>
            <Typography variant="h1">
              <img style={{width: '100%'}} src={logo} alt="CBG"/>
            </Typography>
            <Typography component="h1" variant="h4">
              {t('forgotPasswordTitle')}
            </Typography>
            { this.state.delivery || (authData && authData.username) ?
                <React.Fragment>
                  <Typography variant="body1" style={{marginBottom: '1em'}}>
                    { this.state.delivery
                        ? (this.state.delivery.error
                            ? (this.state.delivery.error === 'LimitExceededException'
                                ? t('forgotPassword_limit', {email})
                                : t('forgotPassword_error', {email}))
                            : t('forgotPassword_delivered', {email}))
                        : t('forgotPassword_delivered_required', {email})
                    }
                  </Typography>
                  <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={this.state.loading}
                      onClick={(e) => this.signIn()}
                  >
                    {t('backToSignin')}
                  </Button>
                </React.Fragment>
                :
                <React.Fragment>
                  <Typography variant="body1">
                    {t('forgotPassword_subtitle')}
                  </Typography>
                  <form className={classes.form} onSubmit={(e) => {
                    e.preventDefault();
                    super.send();
                  }}>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="email">{t('email')}</InputLabel>
                      <Input id="username" name="username" autoComplete="email" type="email" onChange={this.handleInputChange}
                             autoFocus/>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={this.state.loading}
                        className={classes.submit}
                    >
                      {this.state.loading ? <CircularProgress className={classes.progress}/> : null}
                      {t('forgotPassword')}
                    </Button>
                    <Button
                        type="button"
                        fullWidth
                        color="primary"
                        disabled={this.state.loading}
                        onClick={(e) => this.signIn()}
                    >
                      {t('backToSignin')}
                    </Button>
                  </form>
                </React.Fragment>
            }
          </Paper>
        </main>
    );
  }
}

export default withTranslation()(withStyles(styles)(CustomForgotPassword));
