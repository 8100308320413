import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import { Divider, Button, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { TextField } from "formik-material-ui";
import { Field, Form } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
// import { _ = require('lodash/core');
dayjs.extend(customParseFormat);

function validateFamilienaam(value) {
  let error;
  if (value) {
    if (!value || value.length < 2) {
      error = "Verplicht";
    } else if (value.length > 100) {
      error = "Teveel karakters, voer een kortere waarde in.";
    }
  }
  return error;
}

function validateFieldLength(value) {
  let error;
  if (value) {
    if (value.length > 100) {
      error = "Teveel karakters, voer een kortere waarde in.";
    }
  }
  return error;
}

// function validateBirthDate(value) {
//  let error;

//  if (value && !dayjs(value, ['YYYY', 'MM-YYYY', 'DD-MM-YYYY'], true).isValid()) {
//   error = 'Vul een geldig datum formaat in bijv. dd-mm-yyyy, mm-yyyy, yyyy';
//  }
//  return error;
// }
function validateDateOfDeath(value) {
  let error;
  if (value) {
    let splitValue = value.split(" ");
    let checkValue = value;
    if (splitValue.length > 1) {
      for (let val of splitValue) {
        if (val[0] >= "0" && val[0] <= "9") {
          checkValue = val;
        }
      }
    }
    let dateValue = dayjs(checkValue, ["YYYY", "MM-YYYY", "DD-MM-YYYY"], true);

    //   if (checkValue && !dateValue.isValid()) {
    //    error = 'Vul een geldig datum formaat in bijv. dd-mm-yyyy, mm-yyyy, yyyy';
    //   } else
    if (checkValue && dateValue.isValid() && dateValue.isBefore(dayjs("01-01-1934", "DD-MM-YYYY"), "year")) {
      error = "Overlijdensdatum mag niet voor 1934 liggen.";
    }
  }
  return error;
}

function NroPersonForm({ formikProps, totalPersons, setTotalPersons }) {
  const { t } = useTranslation();

  const [error, setError] = React.useState("");
  const [personErrors, setPersonErrors] = React.useState({});

  function errorCheck(values, totalPersons) {
    // console.log("inside errorcheck");
    // console.log({ totalPersons });
    let firstScreenIsValid = false;
    let fieldFilledCount = 0;
    let tempErrors = {};

    for (let i = 1; i <= Object.keys(totalPersons).length; i++) {
      // console.log(totalPersons[`active-${i}`]);
      // console.log(i);
      if (totalPersons[`active-${i}`]) {
        // console.log(totalPersons);
        // console.log({ values });
        // console.log({ fieldFilledCount });
        const voornamenFilled = values[`voornamen-${i}`] ? fieldFilledCount++ : false;
        const geboortedatumFilled = values[`geboortedatum-${i}`] ? fieldFilledCount++ : false;
        const geboorteplaatsFilled = values[`geboorteplaats-${i}`] ? fieldFilledCount++ : false;
        const overlijdensdatumFilled = values[`overlijdensdatum-${i}`] ? fieldFilledCount++ : false;
        const overlijdensplaatsFilled = values[`overlijdensplaats-${i}`] ? fieldFilledCount++ : false;
        const familienaamEchtgenoteFilled = values[`familienaamEchtgenote-${i}`] ? fieldFilledCount++ : false;

        if (fieldFilledCount < 2) {
          tempErrors[i] = `Vul zoveel mogelijk gegevens in, maar ten minste de familienaam en nog twee andere velden.`;
        } else {
          console.log("else errors");
          validateFamilienaam(values[`familienaam-${i}`]) && (tempErrors[i] = validateFamilienaam(values[`familienaam-${i}`]));
          //  validateFieldLength(values[`geboortedatum-${i}`]) && (tempErrors[i] = validateFieldLength(values[`geboortedatum-${i}`]));
          validateDateOfDeath(values[`overlijdensdatum-${i}`]) && (tempErrors[i] = validateDateOfDeath(values[`overlijdensdatum-${i}`]));
        }
        fieldFilledCount = 0;
      }
    }

    setPersonErrors(tempErrors);
    Object.keys(tempErrors).length === 0 ? (firstScreenIsValid = true) : (firstScreenIsValid = false);
    return firstScreenIsValid;
  }

  let entries = Object.entries(totalPersons);

  // console.log(entries);

  let group = _.groupBy(entries, function (b) {
    return b[0].split("-")[b[0].split("-").length - 1];
  });

  // console.log(group);

  let persons = Object.keys(group).map((personIndex) => {
    let valuesArray = group[personIndex];
    let person = {
      id: personIndex,
    };
    valuesArray.forEach((vArray) => {
      person[vArray[0].replace(`-${personIndex}`, "")] = vArray[1];
    });
    return person;
  });

  // console.log(persons);

  // console.log(
  //   persons.filter((p) => {
  //     return p.active === true;
  //   })
  // );

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            Het Nationaal Register van Overledenen bestaat uit twee delen: persoonskaarten van Nederlands ingezetenen die zijn overleden in
            de periode 1939-1994; en persoonslijsten, oftewel de digitale gegevens, van Nederlands ingezetenen die zijn overleden van 1994
            tot heden. Het CBG beheert dit NRO namens de overheid en verstrekt uittreksels van persoonskaarten en persoonslijsten op
            aanvraag.
          </Typography>
          {/* <Typography variant="subtitle1" align="center">
      <Trans i18nKey={'requiredFields'}>
       Velden met een <span className="MuiFormLabel-asterisk">*</span> zijn verplicht
      </Trans>
     </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            {t("personalInformation")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom={true}>
            Vul hieronder de gegevens in van de personen waarvan je een uittreksel wilt aanvragen.
          </Typography>
        </Grid>
        {persons
          .filter((person) => person.active)
          .map(
            (personInfo, index, array) =>
              personInfo.active && (
                <Grid key={personInfo.id} container spacing={2}>
                  {/* {console.log({ personInfo })}
                  {console.log({ index })}
                  {console.log({ array })} */}
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h6" align="center">
                      {`Overledene ${index + 1} (van ${array.length})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/* {index === 0 && index > 0 ? ( */}
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={array.length > 1 ? false : true}
                      onClick={() => {
                        setTotalPersons((prevPersons) => {
                          const updatedPersons = { ...prevPersons };
                          updatedPersons[`active-${personInfo.id}`] = false;

                          // console.log({ updatedPersons });
                          return updatedPersons;
                        });
                      }}
                    >
                      Verwijderen
                    </Button>
                    {/* ) : null}  */}
                    {/* {index >= 4 && index < 5 ? (
        <Typography variant="subtitle1" color="secondary" align="center">
         Let op: Bij meer als 4 personen is een spoedaanvraag niet mogelijk.
        </Typography>
       ) : null} */}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      required
                      name={`familienaam-${personInfo.id}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={<h2>inclusief eventueel voorvoegsel zoals van of in 't</h2>}>
                              <IconButton edge="end">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      label={t("familyname")}
                      validate={validateFamilienaam}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      validate={validateFieldLength}
                      name={`voornamen-${personInfo.id}`}
                      label={t("firstnames")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      validate={validateFieldLength}
                      name={`geboortedatum-${personInfo.id}`}
                      label={t("birthdate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      validate={validateFieldLength}
                      name={`geboorteplaats-${personInfo.id}`}
                      label={t("birthPlace")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name={`overlijdensdatum-${personInfo.id}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <h2>
                                  Overlijdens voor 1939 staan niet opgenomen in het NRO. Met een aantal uitzonderingen voor de periode
                                  1934-1939
                                </h2>
                              }
                            >
                              <IconButton edge="end">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      label={t("dateOfDeath")}
                      validate={validateDateOfDeath}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      validate={validateFieldLength}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <h2>
                                  Overlijdens in het buitenland kunnen alleen geleverd worden als de overledene bij overlijden de
                                  Nederlandse nationaliteit had
                                </h2>
                              }
                            >
                              <IconButton edge="end">
                                <HelpOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      name={`overlijdensplaats-${personInfo.id}`}
                      label={t("placeOfDeath")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      validate={validateFieldLength}
                      name={`familienaamEchtgenote-${personInfo.id}`}
                      label={t("familynamePartner")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {personErrors[personInfo.id] && <Typography color="error">{personErrors[personInfo.id]}</Typography>}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )
          )}
      </Grid>

      <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                // console.log(`totalPersons: ${Object.keys(totalPersons).length}`);
                setTotalPersons((prevPersons) => ({
                  ...prevPersons,
                  [`active-${Object.keys(totalPersons).length + 1}`]: true,
                }));
                // console.log({ totalPersons });
              }}
            >
              Nog een uittreksel aanvragen
            </Button>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={() => {
                const errorCheckres = errorCheck(formikProps.values, totalPersons);
                // console.log(errorCheckres);
                if (errorCheckres) {
                  formikProps.setFieldValue("showStepTwo", true);
                }
              }}
              variant="contained"
              color="primary"
            >
              Aanvraag afronden
            </Button>
          </Grid>
        </Grid>
      </div>

      {error && <>{error}</>}
    </Form>
  );
}

export default NroPersonForm;
