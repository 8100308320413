/* eslint-disable react/jsx-no-target-blank */

import React, {useEffect, useState} from 'react';

import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation, withTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
Amplify.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));


function Status(props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [userName, setUsername] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(r => setUsername(r.getUsername()));
  }, []);

  return (
      <main className={classes.main}>
        <CssBaseline/>
        <Paper className={classes.paper}>
          <Typography variant="h2" gutterBottom>
            Ingelogd
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t('email')}: {userName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <a href={"https://account.cbg.outsite.app/logout?redirect_uri=https://account.cbg.outsite.app/status"}><Button color="secondary" variant="contained">{t('signout')}</Button></a>
          </Typography>
        </Paper>
      </main>
  );
}


export default withTranslation()(Status);