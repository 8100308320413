import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from './Routes'

import { SignUp } from 'aws-amplify-react';
import CssBaseline from "@material-ui/core/CssBaseline";

import {BrowserRouter, Route, Switch} from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";
import Verification from "./components/Verification";
import VerifyEmail from "./components/VerifyEmail";
import ResetPassword from "./components/ResetPassword";
import Logout from "./components/Logout";
import IFrame from "./components/IFrame";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#A59567', // CBG Verzamelingen
          //main: '#A49660',
          //main: '#AF936C'
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#4A90E2',
            contrastText: '#ffffff',
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'frescoregular',
            'Arial',
            'Helvetica',
            'sans-serif'
        ].join(',')
    },
    overrides: {
        MuiTypography: {
            h6: {
                fontFamily: [
                    'frescoregular',
                    'Georgia',
                    '"Times New Roman"',
                    'Times',
                    'serif'
                ].join(',')
            },
            h4: {
                fontFamily: [
                    'frescoregular',
                    'Georgia',
                    '"Times New Roman"',
                    'Times',
                    'serif'
                ].join(',')
            }

        },
        MuiButton: {
            root: {
                borderRadius: 100,
                minHeight: 30,
                textTransform: "none",
                fontSize: "1.1rem",
            }
        },
        MuiFormLabel: {
          asterisk: {
              color: "#db3131",
              "&$error": {
                  color: "#db3131"
              }
          }
        },
    },
});


class App extends Component {
    render() {
        return (
            <div>
              <CssBaseline/>
                <MuiThemeProvider theme={theme}>
                    <BrowserRouter>
                        <ScrollToTop>
                            <Switch>
                                <Route exact path='/register' component={ SignUp } />
                                {/*<Route path='/verify/email/:sub/:code' component={ EmailVerification } />*/}
                                <Route path='/verify/:sub/:code' component={ Verification } />
                                <Route path='/new_email/:sub/:code' component={ VerifyEmail } />
                                <Route path='/forgot_password/:sub/:code' component={ ResetPassword } />
                                <Route path='/logout' component={ Logout } />
                                <Route exact path='/iframe' component={ IFrame } />
                                <Route component={Routes}/>
                            </Switch>
                        </ScrollToTop>
                    </BrowserRouter>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default App;
