import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import logo from "../images/logo-cbg-corporate.svg";
import {parse} from "query-string";
Amplify.configure(awsconfig);

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
            width: 550,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: '700',
    },
    title: {
        marginTop: theme.spacing(2),
    },
});

function Logout(props) {
    const { t } = useTranslation();
    const { location, classes } = props;
    const search = parse(location.search);
    // console.log(location.state);

    useEffect(() => {
        Auth.signOut().then(r => {
            if (search.redirect_uri) {
                const url = new URL(search.redirect_uri);
                setTimeout(() => {
                    window.location.replace(url.href);
                }, 2000);

            }
        });
    }, [search.redirect_uri]);

    if (props.location && props.location.state && props.location.state.email) {
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h1">
                        <img style={{width: '100%'}} src={logo} alt="CBG"/>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {t('verification')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('logout_newEmail', {email: props.location.state.email})}
                    </Typography>
                    <Button component={Link}
                            to="/"
                            variant="contained"
                            color="secondary">
                        {t('signin')}
                    </Button>
                </Paper>
            </main>
        );

    } else if (props.location && props.location.state && props.location.state.newEmail) {
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h1">
                        <img style={{width: '100%'}} src={logo} alt="CBG"/>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {t('verification')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('changeEmail_delivered', {email: props.location.state.newEmail})}
                    </Typography>
                </Paper>
            </main>
        );

    } else {
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Typography variant="h1">
                        <img style={{width: '100%'}} src={logo} alt="CBG"/>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {t('signout')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('confirmSignout')}
                    </Typography>
                </Paper>
                <iframe title="outsite_logout" width={1} height={1} src={`${process.env.REACT_APP_OUTSITE_URI}/?action=logout`}></iframe>
            </main>
        );

    }
}

Logout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logout);
