import React, {useEffect, useState} from 'react';

import Amplify from 'aws-amplify';
import awsconfig from '../aws-config-manual';
import {useCode} from "../hooks/useCode";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {CircularProgress} from "@material-ui/core";
Amplify.configure(awsconfig);

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

function CognitoApp(props) {
  const {classes} = props;

  const [host, setHost] = useState('');
  const [code] = useCode();

  useEffect(() => {
    if (code) {
      const url = new URL(process.env.REACT_APP_CBG_URI);
      setHost(url.host);
      url.searchParams.append('code', code);
      window.location.replace(url.href);
    }
  }, [code]);

  return (
      <main className={classes.main}>
        <CssBaseline/>
        <p>
          <CircularProgress color="secondary" />
          { !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?  <span>Redirecting to: {host}</span> : null }
        </p>
      </main>
  );
}

CognitoApp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CognitoApp);
