import React, { useEffect, useState } from "react";

import Amplify, { API, Auth } from "aws-amplify";
import awsconfig from "../aws-config-manual";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import { ErrorDialog } from "./ErrorDialog";
import OrderNroPayment from "./OrderNroPayment";
import OrderNroPersonForm from "./OrderNroPersonForm";
import { useTranslation, getI18n } from "react-i18next";
import { DateUtils } from "@aws-amplify/core";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

// Auth.configure({
//   authenticationFlowType: 'USER_PASSWORD_AUTH'
// });

// const products = [
//   {
//     id: 20,
//     title: 'Nro Aanvraag',
//     price: 0,
//     buttonText: 'Nro aanvraag',
//     abroadPrice: 0,
//   },
// ];

const styles = (theme) => ({
  layout: {
    width: "auto",
    margin: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2 * 2))]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

function OrderNro(props) {
  const { classes, match } = props;
  const { t } = useTranslation();

  const [productId, setProductId] = useState(match.params.productId);
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState();

  const [totalPersons, setTotalPersons] = useState({ "active-1": true });
  const [personPrijsTotaal, setPersonPrijsTotal] = useState(9.8);

  const [pricelist, setPricelist] = useState([]);

  useEffect(() => {
    async function getPriceList() {
      const apiName = "oAuth";
      const path = `/shop/pricelist`;
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const session = cognitoUser.getSignInUserSession();
        DateUtils.setClockOffset(session.clockDrift * 1000 * -1);

        const res = await API.get(apiName, path);
        // console.log(res);
        setPricelist(res);
      } catch (e) {
        console.log(e);
      }
    }

    getPriceList();
  }, []);

  //  const widget = markerSDK.loadWidget({ project: '642d179ebd207f28f25a95c3', source: 'snippet' });

  // personForm
  // const [initialPersonValues, setInitialpersonValues] = useState({});
  // const [valuesPerson, setValuesPerson] = useState({});
  // const [errorPerson, setErrorPerson] = useState({});
  // const [openPerson, setOpenPerson] = useState(false);
  // const [isLoadingPerson, setLoadingPerson] = useState(true);
  // const [isSubmittingPerson, setSubmittingPerson] = useState(false);

  // const { title, price, abroadPrice, discount } = products[productId];

  useEffect(() => {
    async function getPersonInfo(productId) {
      if (true) {
        const language = getI18n().language;
        const apiName = "oAuth";
        const path = "/shop/personInfo";
        const queryStringParameters = { productId };
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
          // console.log(queryStringParameters);
          const res = await API.get(apiName, path, { queryStringParameters });
          if (res.message === "incompatibleProduct") {
            setLoading(false);
            setError(res);
          } else {
            setProductId(res.productId);
            for (const key in res) {
              if (res[key] === null) {
                res[key] = "";
              }
            }
            // setInitialpersonValues({ ...res, language });
            setInitialValues({ ...res, language, terms: false, spoedAanvraag: false });
            // setLoadingPerson(false);
            setLoading(false);
          }
        } catch (err) {
          console.error(err.message);
          console.error(err);
          // setInitialpersonValues({});
          setInitialValues({});
          // setLoadingPerson(false);
          setLoading(false);
          setError(err);
        }
      }
    }
    getPersonInfo(productId);
  }, [productId]);

  useEffect(() => {
    if (isSubmitting) {
      async function submitForm() {
        console.log("submitted:");
        // console.log(values);
        // console.log("insert into afas");

        const apiName = "oAuth";
        let path = `/shop/ordernro/111`;
        if (values.personPrijsTotaal === 0) {
          path = `/shop/ordernro/0`;
        }

        let myInit = {
          body: values,
        };
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);

          const res = await API.post(apiName, path, myInit);

          // console.log(res);
          if (res.mollieId && res.checkoutUri) {
            window.location.assign(res.checkoutUri);
          } else {
            setRedirect(`/shop/pay/${res.orderId}`);
          }
        } catch (err) {
          console.error(err);
          if (err.name.includes("500")) {
            let errorr = {
              name: "Technische storing",
              message: "Door een technische storing kunnen we je aanvraag nu niet verwerken. Probeer het later nog eens.",
            };
            setOpen(errorr.message);
            setError(errorr);
          } else {
            setOpen(err.message);
            setError(err);
          }
        }
      }
      submitForm();
    }
  }, [isSubmitting, values, productId]);

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h2" align="center">
            UITTREKSELS Nationaal Register van Overledenen (NRO)
          </Typography>
        </Paper>
        {error.message ? (
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h3" align="center">
              {t(error.message)}
            </Typography>
            <Button variant="contained" color="primary" shape="chubby" href={t("signup_info_link")}>
              {t("Bestellen")}
            </Button>
            <Button variant="contained" color="primary" shape="chubby" href={process.env.REACT_APP_OUTSITE_URI}>
              {t("Mijn profiel")}
            </Button>
            <Button variant="contained" color="primary" shape="chubby" href={process.env.REACT_APP_CBG_URI}>
              CBG.nl
            </Button>
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            {redirect ? (
              <Button variant="contained" color="primary" shape="chubby" component={Link} to={redirect}>
                Markeer als betaald
              </Button>
            ) : isLoading || isSubmitting ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Formik
                onSubmit={(values) => {
                  setValues({ ...values, personPrijsTotaal: personPrijsTotaal, totalPersons: totalPersons });
                  setSubmitting(true);
                }}
                initialValues={initialValues}
                validate={(values) => {
                  let errors = {};

                  return errors;
                }}
              >
                {(props) => {
                  return (
                    <>
                      {(!props.values.showStepTwo || props.values.showStepTwo === false) && (
                        <OrderNroPersonForm formikProps={props} totalPersons={totalPersons} setTotalPersons={setTotalPersons} />
                      )}

                      {props.values.showStepTwo === true && (
                        <OrderNroPayment
                          formikProps={props}
                          classes={classes}
                          //   personPrijsTotaal={personPrijsTotaal}
                          setPersonPrijsTotal={setPersonPrijsTotal}
                          pricelist={pricelist}
                          totalPersons={totalPersons}
                        />
                      )}
                    </>
                  );
                }}
              </Formik>
            )}
          </Paper>
        )}
        <ErrorDialog open={open} error={error} onClose={() => setOpen(false)} />
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(OrderNro);
